// import logo from './logo.svg';
import './App.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import NavBar  from './NavBar';
import FileUpload from './FileUpload'
import Instructions from './Instructions';
import Info from './Info';
import Footer from './Footer'
import Policy from './Policy'

function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route path="/" element={
            <div className="App">
              <NavBar/>
              <Instructions/>
              <FileUpload/>
              <Info/>
              <Footer/>
            </div>
          }/>
          <Route path="/policy" element={<Policy/>}/>
        </Routes>
    </BrowserRouter>
  );
}

export default App;
