import './NavBar.css';
import logo from './images/logo192.png'
import { useNavigate } from "react-router-dom";

function NavBar(){
    const navigate = useNavigate();
    return (
        <nav className="navbar">
            <img width={45} src={logo} onClick={() => navigate("/")}></img>
            <h3>
            free pdf 2 txt
            </h3>
        </nav>
      );
}

export default NavBar;