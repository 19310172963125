// import logo from './logo.svg';
import './Policy.css';
import { useNavigate } from "react-router-dom";

import React, { useState } from 'react';

import { Stack } from '@mui/material';
import { ButtonBase } from '@mui/material';

import NavBar  from './NavBar';
import logo from './images/logo192.png';
import ReactMarkdown from 'react-markdown'
import privacy from './policy/privacy.md'

function Policy() {
  const navigate = useNavigate();
  const [privacyText, setPrivacyText] = useState<string>('')

  fetch(privacy)
  .then(r => r.text())
  .then(text => {
    setPrivacyText(text);
  });

  return (
    <div className="policy">
      <NavBar />
      <div className='policytext'>
        <ReactMarkdown>{privacyText}</ReactMarkdown>
      </div>
      
      <div className='footer'>
        <div className='parent'>
        <Stack className='logo' direction="row" spacing={2}>
          <img width={30} src={logo}></img>
          <span>free pdf 2 txt</span>
        </Stack>
        
        <Stack className='copyright' direction='row'>
          <div>&copy; 2024 free pdf 2 txt inc. &nbsp;</div>
          <div >All Rights Reserved &nbsp;</div>
          <div className='links' onClick={() => navigate("/")} >Home &nbsp;</div>
        </Stack>
        </div>
      </div>
    </div>
  );
}

export default Policy;
