import React, { useCallback, useState, useEffect } from 'react'
import { useDropzone, FileRejection } from 'react-dropzone'
import axios, { AxiosError, AxiosResponse } from "axios";
import Button from '@mui/material/Button';
import { Stack, TextField } from '@mui/material';

import uploadImage from './images/upload1.png'
import ReplayIcon from '@mui/icons-material/Replay';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DownloadIcon from '@mui/icons-material/Download';
import DoneIcon from '@mui/icons-material/Done';
import Grid from '@mui/material/Grid';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import './FileUpload.css';
import SelectedFile from './SelectedFile';

import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { error } from 'console';

const baseURL = "https://freepdf2txt.com/api/upload";

function handleError(error: AxiosError) {

  const ErrorMessages = {
    400: 'There was a problem while processing your request', // not being used currently
    401: '401:Unauthorized - You are not authorized to access this resource.',
    403: '403:Forbidden - You don’t have permission to access this resource',
    404: 'API Route is Missing or Undefined',
    405: 'API Route Method Not Allowed',
    422: '422:Unprocessable Entity',
    429: '429:Too Many Requests - You have reached the maximum requests of 10 per day. Try again tomorrow.',
    500: '500:Server Error - Please try again later',
    request:
      'There is a problem with our servers, Please try again later',
    other:
      'There was a problem submitting the request, Please try again later',
  }

  if (error && error.response) {
    // client received an error response (5xx, 4xx)
    if (error.response.status === 400) {
      return ErrorMessages['400']
    } else if (error.response.status === 401) {
      return ErrorMessages['401']
    } else if (error.response.status === 403) {
      return ErrorMessages['403']
    } else if (error.response.status === 404) {
      return ErrorMessages['404']
    } else if (error.response.status === 422) {
      return ErrorMessages['422']
    } else if (error.response.status === 405) {
      return ErrorMessages['405']
    } else if (error.response.status >= 500) {
      return ErrorMessages['500']
    } else if (error.response.status === 429) {
      return ErrorMessages['429']
    }
    else {
      return ErrorMessages.other
    }
  } else if (error && error.request) {
    return ErrorMessages.request
  } else if (error instanceof Error) {
    return error.message
  } else if (typeof error === 'string') {
    return error
  } else {

    return ErrorMessages.other
  }
}

async function postFile(file: File | null) {
  if (file != null) {

    let formData = new FormData();
    formData.append("file", file);

    return axios
      .post(baseURL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      })
      .then((response) => {

        return response

      }).catch(err => {

        return err;
      });
  }
}

// if file fails this validation, it becomes a rejected file
function fileSizeValidator(file: File) {
  if (file.size > 5000000) {
    return {
      code: "size-too-large",
      message: `File is larger than 5MB`
    };
  }

  return null
}

function FileUpload() {

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const downloadTxtFile = () => {
    const element = document.createElement("a");
    const file = new Blob([resultState], {
      type: "text/plain"
    });
    element.href = URL.createObjectURL(file);
    element.download = currentFile == null ? '' : currentFile.name + ".txt";
    document.body.appendChild(element);
    element.click();
  };

  const [currentFile, setfile] = useState<File | null>(null)
  const [resultState, setResult] = useState<string>('')
  //const [errorState, setError] = useState<string>('')
  const [userAlert, setUserAlert] = useState<[string,string]>(['', '']) //first is title, second is message
  const [copiedState, setCopied] = useState<boolean>(false)

  const [loading, setLoading] = React.useState(() => {
    return false
  });
  const [success, setSuccess] = React.useState(() => {
    return false
  });

  useEffect(() => {
    setResult('')
    setCopied(false)
    setSuccess(false)
  }, [currentFile]) // <-- here put the parameter to listen


  const onDrop = useCallback((
    acceptedFiles: File[], rejectedFiles: FileRejection[]) => {

    acceptedFiles.forEach((file) => {
      console.log('accepted', file)
      if (file != undefined)
        setfile(file)
    })

    if(rejectedFiles.length > 1){
      // alert user - only submit one file
      setUserAlert(['Invalid Operation', 'Too many files! Please select only one file.'])
      handleOpen()
    }

    if(rejectedFiles.length == 1){
      // message
      var message = ''

      // check size
      var isTooBig = false
      
      if( rejectedFiles[0].file.size > 5000000){
        
        isTooBig = true
        message += 'File size exceeds 5MB'
      }

      // check file type
      var isFileTypeInvalid = false

      if(rejectedFiles[0].file.type != "application/pdf"){
        isFileTypeInvalid = true
        if(isTooBig == true)
          message += ' and '
        message += 'File type is not PDF'
      }

      // alert user
      if(isTooBig || isFileTypeInvalid){
        message += ". Please select another file."
        setUserAlert(['File rejected for the following reasons...', message])
        handleOpen()
      }
      
    }


  }, [])

  const { 
    getRootProps, 
    getInputProps, 
    isDragActive 
  } = useDropzone({ 
      onDrop, 
      multiple: false,
      accept:{ 'application/pdf': ['.pdf'] },
      validator: fileSizeValidator
    })


  return (
    <div className='parent' >

      {/* <Box sx={{ display: 'flex', 'justify-content': 'center' }}> */}
      <div className='fileUpload'  {...getRootProps()}>
        <img width={75} src={uploadImage}></img>
        {/* <UploadFileIcon htmlColor='#00a4b9' sx={{ 'fontSize': '100px' }}></UploadFileIcon> */}
        <input {...getInputProps()} />
        {
          isDragActive ?
            <p>Drop the file here ...</p> :
            <p>Drag and drop a file here, or click to select a file </p>

        }
        {currentFile != null && <SelectedFile filename={currentFile.name}></SelectedFile>}
      </div>
      {/* </Box> */}

      <Stack direction="row" spacing={2} sx={{ display: 'flex', 'justify-content': 'center', 'align-items': 'center' }}>
        <Button variant="contained"  disabled={loading || (currentFile == null)} onClick={() => {
          setLoading(true)
          setSuccess(false)
          setCopied(false)

          postFile(currentFile).then(response => {

            if (response) {
              if (response.isAxiosError == true) {
                let result = handleError(response);
                setResult('')
                setSuccess(false)
                setUserAlert(['Error', result])
                handleOpen()
              } else {
                setResult(response.data)
                setSuccess(true)
                setUserAlert(['', ''])
              }

              setLoading(false)

            } else {

            }
            
          });
        }} endIcon={<PlayArrowIcon />}>CONVERT</Button>
        {
          success == true ? <DoneIcon sx={{ color: 'green', fontSize: 'xx-large' }}></DoneIcon> : <CircularProgress sx={!loading ? { visibility: 'hidden' } : { visibility: 'visible' }} />
        }

        <Button variant="outlined" color="error" disabled={loading || (currentFile == null)} onClick={() => {
          setfile(null)
          setResult('')
          setSuccess(false)
          setUserAlert(['', ''])
        }} endIcon={<ReplayIcon />}>RESET</Button>
      </Stack>

      <textarea className='result' readOnly rows={15} cols={50} value={resultState} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {userAlert[0]}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {userAlert[1]}
          </Typography>
          <Grid container justifyContent="flex-end">
            <Button color='info' variant="outlined" onClick={handleClose}>CLOSE</Button>
          </Grid></Box>

      </Modal>
      <Stack direction="row" spacing={2} sx={{ display: 'flex', 'justify-content': 'center', 'margin-bottom': '30px' }}>
        <Button variant="outlined" color="success" disabled={loading || (resultState == '')} endIcon={
          copiedState == true ? <DoneIcon /> : <ContentCopyIcon />
        } onClick={() => {
          navigator.clipboard.writeText(resultState);
          setCopied(true);
        }}>{
            copiedState == true ? "copied" : "copy"
          }</Button>
        <Button variant="outlined" color="success" disabled={loading || (resultState == '')} endIcon={<DownloadIcon />} onClick={downloadTxtFile}>DOWNLOAD</Button>
      </Stack>
    </div>
  )
}



export default FileUpload;