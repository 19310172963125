import './FileUpload.css';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { PropaneSharp } from '@mui/icons-material';

type props = {
    filename: string;
  }; 

function SelectedFile(props: props ){

    return (
        <div className="filename">
            <PictureAsPdfIcon sx={{'color':'darkred'}}></PictureAsPdfIcon><text className='filename'>{props.filename}</text>
        </div>
      );
}

export default SelectedFile;