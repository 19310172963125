import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import './Instructions.css';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

function Instructions() {

    return (
        <div className='instructions'>
            <Stack className='intro' direction="column" spacing={5} >
                <span>Freepdf2txt.com is a free online PDF to text converter that can extract text data from PDF files in seconds. Simply follow the instructions below to get started.  Text will be extracted from all pages including those pages that contain images.  Any text that occurs in images will be converted using OCR.</span>
            </Stack>

            <Stack sx={{'margin-top': '30px', 'font-weight': 'bold'}} direction="row" spacing={2}>
                <span>How to extract text from PDF</span>
            </Stack>

            <Stack className='howto' direction="row" spacing={2}>
                <ol>
                    <li><span>Select the PDF file you wish to convert (5MB file size limit)</span></li>
                    {/* <ArrowForwardIcon sx={{'color' : '#008fa2'}}></ArrowForwardIcon> */}
                    <li><span>Click the Convert button and the text will be extracted in a few seconds</span></li>
                    {/* <ArrowForwardIcon sx={{'color' : '#008fa2'}}></ArrowForwardIcon> */}
                    <li><span>Copy or download the result to your computer!</span></li>
                </ol>
            </Stack>

        </div>
    );
}

export default Instructions;