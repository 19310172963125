
import './Info.css';
import Grid from '@mui/material/Unstable_Grid2'
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import EnhancedEncryptionOutlinedIcon from '@mui/icons-material/EnhancedEncryptionOutlined';
import AutoDeleteOutlinedIcon from '@mui/icons-material/AutoDeleteOutlined';
import Stack from '@mui/material/Stack';
import ComputerOutlinedIcon from '@mui/icons-material/ComputerOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';

function Info() {
  return (
    <Box className="box">
      <section className='grid' >
        <div className="feature">
          <Stack direction="row" className='stack' >
            <span >
              <EnhancedEncryptionOutlinedIcon fontSize="large"></EnhancedEncryptionOutlinedIcon>
            </span>
            <h3>Secure</h3>
          </Stack>
          <p>Your data is secure. 256-bit SSL Encryption of all your uploaded files. Data will never be shared with any other parties.</p>
        </div>
        <div className="feature">
          <Stack direction="row" className='stack' >
            <span >
              <AutoDeleteOutlinedIcon fontSize='large'></AutoDeleteOutlinedIcon>
            </span>
            <h3 >Automatic Deletion</h3>
          </Stack>
          <p>Uploaded files are automatically deleted from servers as soon as the conversion process is completed.  Nothing is ever stored and no action is needed from you.</p>
        </div>
        <div className="feature">
          <Stack direction="row" className='stack' >
            <span >
              <TextSnippetOutlinedIcon fontSize='large'></TextSnippetOutlinedIcon>
            </span>
            <h3 >Processable Text</h3>
          </Stack>
          <p>PDF is converted to text that can be searched, edited, and even processed by other applications.</p>
        </div>
        <div className="feature">
          <Stack direction="row" className='stack' >
            <span >
              <ComputerOutlinedIcon fontSize='large'></ComputerOutlinedIcon>
            </span>
            <h3 >Universal</h3>
          </Stack>
          <p>Browser based tool that works on any OS that has a browser, including Windows, Linux and Mac.</p>
        </div>
      </section>


    </Box>
  );
}

export default Info;
