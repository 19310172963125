import './Footer.css';
import axios, { AxiosError, AxiosResponse } from "axios";
import React, { useState, useEffect } from 'react';
import logo from './images/logo192.png';
import { Stack } from '@mui/material';
import { useNavigate } from "react-router-dom";

import { Modal, Link, Grid, Button, ButtonBase } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ReactMarkdown from 'react-markdown'
import privacy from './policy/privacy.md'

import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import TextField from '@mui/material/TextField';

import emailjs from 'emailjs-com';

const baseURL = "https://freepdf2txt.com/api/";

async function postMessage(data: String | null) {
  if (data != null) {
    return axios
      .post(baseURL + 'contact', data, {
        headers: {
          "Content-Type": "application/json",
        }
      })
      .then((response) => {

        return response

      }).catch(err => {

        return err;
      });
  }
}

function Footer() {
  const navigate = useNavigate();
  const [scroll, setScroll] = useState<DialogProps['scroll']>('paper');
  const descriptionElementRef = React.useRef<HTMLElement>(null);

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [privacyPolicyOpen, setOpenPrivacyPolicy] = useState(false);
  const [supportOpen, setOpenSupport] = useState(false);

  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactMessage, setContactMessage] = useState('');

  const handleClose = () => {
    setOpenPrivacyPolicy(false);
  };

  const handleOpen = () => {
    setOpenPrivacyPolicy(true);
  };

  const [userAlert, setUserAlert] = useState<[string,string]>(['', '']) //first is title, second is message
  const [open, setOpen] = React.useState(false);
  const handleModalOpen = () => setOpen(true);
  const handleModalClose = () => setOpen(false);

  const handleCloseSupport = () => {
    setOpenSupport(false);
    handleClearSupportForm();
  };

  const handleClearSupportForm = () => {
    setContactName('');
    setContactEmail('');
    setContactMessage('');
  };

  const handleOpenSupport = () => {
    setOpenSupport(true);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = {
      from_name: contactName,
      reply_to: contactEmail,
      message: contactMessage
    }
    //const jsonString = JSON.stringify(data);

    emailjs.send('default_service', 'template_qzpy5yv', data, 'EPuzO_Bm_OhjspOw7')
    .then((result) => {
        //console.log(result.text);
        handleCloseSupport();
        setUserAlert(['Success', 'Message has been sent.'])
        setOpen(true)

    }, (error) => {
        //console.log(error.text);
        handleCloseSupport();
        setUserAlert(['Error', 'The message was not sent due to an error, please try again later'])
        setOpen(true)
    });


  };

  const [count, setCount] = useState<number>(0)

  const [privacyText, setPrivacyText] = useState<string>('')

  const getCount = async () => {
    try {
      const response = await axios.get(baseURL + 'count', {
        headers: {

        },
      })
      console.log(response.data[0]);
      setCount(response.data[0])
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getCount();
  }, []);


  fetch(privacy)
    .then(r => r.text())
    .then(text => {
      setPrivacyText(text);
    });

  return (
    <div id="1" className='footer' >
      <div className='parent'>
        <Stack className='logo' direction="row" spacing={2}>
          <img width={30} src={logo}></img>
          <span>free pdf 2 txt</span>
        </Stack>
        <span className='count'>{count}</span>
        <span className='counttext'>files converted since 2023</span>
        <Stack className='copyright' direction='row'>
          <div>&copy; 2024 free pdf 2 txt inc. &nbsp;</div>
          <div>All Rights Reserved &nbsp;</div>
          <div className='links' onClick={() => navigate("/policy")}>Terms and Privacy Policy &nbsp;</div>
          <div className='links' onClick={handleOpenSupport}>Support</div>
        </Stack>
      </div>
      <Dialog
        maxWidth={'md'}
        open={privacyPolicyOpen}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        {/* <DialogTitle id="scroll-dialog-title">Terms of Use and Privacy Policy</DialogTitle> */}
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <ReactMarkdown>{privacyText}</ReactMarkdown>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth={'md'}
        open={supportOpen}
        onClose={handleCloseSupport}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Contact</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >

            <div >
              <p>Please send an e-mail if you have any feedback, technical issues, or require further assitance.</p>
              <form onSubmit={handleSubmit}>
                <Stack direction="column" spacing={2}>
                  <Stack direction="column" spacing={2}>
                    <TextField label="Name" variant="outlined" required 
                    value={contactName} onChange={(e) => setContactName(e.target.value)}/>
                    <TextField label="E-Mail" variant="outlined" required 
                    value={contactEmail} onChange={(e) => setContactEmail(e.target.value)}/>
                  </Stack>
                  <Stack direction="column" spacing={2}>
                    <TextField label="Message" multiline rows={7} required 
                    value={contactMessage} onChange={(e) => setContactMessage(e.target.value)}/>
                    <Grid container justifyContent="flex-end">
                      <Stack direction="row" spacing={2}>
                        <Button type="submit" variant="contained" sx={{ width: 100, backgroundColor: '#0092d6' }}>
                          Send
                        </Button>
                        <Button variant="outlined" sx={{ width: 100 }} onClick={handleCloseSupport}>Cancel</Button>
                      </Stack>
                    </Grid>
                  </Stack>
                </Stack>
              </form>

            </div>
          </DialogContentText>
        </DialogContent>
        {/*         <DialogActions>
          <Button variant="outlined" onClick={handleCloseSupport}>Cancel</Button>
        </DialogActions> */}
      </Dialog>
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {userAlert[0]}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {userAlert[1]}
          </Typography>
          <Grid container justifyContent="flex-end">
            <Button color='info' variant="outlined" onClick={handleModalClose}>CLOSE</Button>
          </Grid></Box>

      </Modal>
    </div>
  );
}

export default Footer;